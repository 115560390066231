/**
 *  Very lite javascript/styling for the login page
 *  but it also loads other things in the background
 */

import "./login/style.scss";

let redirectURL = "";
if (window.location.hash) {
    const redirect = window.location.hash.match(/\#redirect=(.*)/);
    if (redirect && redirect[1]) {
        redirectURL = atob(redirect[1]);
        console.log("Redirecting to " + redirectURL);
    }
}

if ("credentials" in navigator) {
    navigator.credentials.get({ password: true })
        .then((account: any) => {
            if (account && account.name && account.password) {
                submit(account.name, account.password);
            }
        });
}

if (!String.prototype.repeat) {
    String.prototype.repeat = function (count) {
        'use strict';
        if (this == null) {
            throw new TypeError('can\'t convert ' + this + ' to object');
        }
        var str = '' + this;
        count = +count;
        if (count != count) {
            count = 0;
        }
        if (count < 0) {
            throw new RangeError('repeat count must be non-negative');
        }
        if (count == Infinity) {
            throw new RangeError('repeat count must be less than infinity');
        }
        count = Math.floor(count);
        if (str.length == 0 || count == 0) {
            return '';
        }
        // Ensuring count is a 31-bit integer allows us to heavily optimize the
        // main part. But anyway, most current (August 2014) browsers can't handle
        // strings 1 << 28 chars or longer, so:
        if (str.length * count >= 1 << 28) {
            throw new RangeError('repeat count must not overflow maximum string size');
        }
        var rpt = '';
        for (var i = 0; i < count; i++) {
            rpt += str;
        }
        return rpt;
    }
}

let dots = 0;
let dotterTimer: NodeJS.Timer;
function dotter() {
    dots++;
    if (dots >= 4) {
        dots = 0;
    }
    const button = document.getElementById("submit") as HTMLButtonElement;
    button.value = "Signing In" + ".".repeat(dots);
    dotterTimer = setTimeout(dotter, 400);
}

function submit(username: string, password: string) {
    try {
        const button = document.getElementById("submit") as HTMLButtonElement;
        dotter();
        button.disabled = true;
        const xmlhttp = new XMLHttpRequest();
        const data = "login=" + encodeURIComponent(username) + "&password=" + encodeURIComponent(password) + "&remember_me=on";
        console.log("xmlhttp", xmlhttp);
        xmlhttp.open("POST", "/login", true);
        xmlhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xmlhttp.onreadystatechange = () => {
            if (xmlhttp.readyState === XMLHttpRequest.DONE && xmlhttp.status === 200) {
                const response = xmlhttp.responseText;
                // Failed login
                // We check if response has gears_login, meaning server gave us back /login page
                if (response.indexOf("gears_login") >= 0) {
                    console.log("Failed Login");
                    if ("credentials" in navigator) {
                        if ("preventSilentAccess" in navigator.credentials) {
                            navigator.credentials.preventSilentAccess();
                        } else {
                            navigator.credentials.requireUserMediation();
                        }
                    }
                    window.location.reload();
                } else {
                    clearTimeout(dotterTimer);
                    const button = document.getElementById("submit") as HTMLButtonElement;
                    button.value = "Logged In";
                    // TODO: Fix this so firefox can auto login same as chrome
                    if ("credentials" in navigator && global.PasswordCredential) {
                        navigator.credentials.store(new PasswordCredential({
                            id: username,
                            name: username,
                            password,
                        })).then(() => {
                            if (redirectURL) {
                                console.log("Logged in, redirecting");
                                window.location.href = redirectURL;
                            } else {
                                console.log("Logged in, not redirecting");
                                window.location.href = xmlhttp.responseURL;
                            }
                        }).catch(() => {
                            if (redirectURL) {
                                console.log("Logged in, redirecting");
                                window.location.href = redirectURL;
                            } else {
                                console.log("Logged in, not redirecting");
                                window.location.reload();
                            }
                        });
                    } else {
                        if (redirectURL) {
                            console.log("Logged in, redirecting");
                            window.location.href = redirectURL;
                        } else {
                            console.log("Logged in, not redirecting");
                            window.location.href = xmlhttp.responseURL;
                        }
                    }
                }
            }
        };
        xmlhttp.send(data);
    } catch (e) {
        alert(e.toString());
    }
}

window.addEventListener("load", () => {
    document.getElementById("login-form").addEventListener("submit", (e) => {
        e.preventDefault();
        try {
            //const form = new FormData(document.getElementById("login-form"));
            const username = document.getElementById("email").value; //form.get("login");
            const password = document.getElementById("password").value;
            if (username && password) {
                submit(username, password);
            }
        } catch (e2) {
            alert(e2.toString());
        }
        return false;
    });
});
